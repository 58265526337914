import React from 'react';

const baseURL = 
  // "http://api.kkpartner.dk" 
  // "https://localhost:3001"
  // "http://remote.kkpartner.dk"
  // "http://127.0.0.1:3001"
  // "http://localhost:3000"
  // "http://10.10.60.83:3000"
  "https://kkpartnerapi.herokuapp.com"

  
const routePrefix = 
""
//"/react"

const status = {
  "AFVENTER": {
    image: <img style={{height: "26px"}} className="spinning" src={"/images/waitingIcon.svg"} alt={"Missing Image"}/>,
    imageBig: <img style={{height: "39px"}} src={"/images/waitingIcon.svg"} alt={"Missing Image"}/>,
    className: "awaiting",
    text: "ACCEPTERET",
    statusStyling: {color: "#42CB6B", border: "1px solid #42CB6B", background: "none"},
    statusRejectedStyling: {color: "#DF4848", border: "1px solid #DF4848", background: "none"},
    statusRejectedHover: "#DF4848",
    statusHover: "#42CB6B",
    ownColor: "#F3AB23"
  },
  "ACCEPTERET": {
    image: <img style={{height: "22px"}} src={"/images/checkmarkIcon.svg"} alt={"Missing Image"}/>,
    imageBig: <img style={{height: "33px"}} src={"/images/checkmarkIcon.svg"} alt={"Missing Image"}/>,
    className: "ready",
    text: "I GANG",
    statusStyling: {color: "#008DFF", border: "1px solid #008DFF", background: "none"},
    statusHover: "#008DFF",
    ownColor: "#42CB6B"
  },
  "I GANG": {
    image: <img style={{height: "26px"}} src={"/images/infoIcon.svg"} alt={"Missing Image"}/>,
    imageBig: <img style={{height: "39px"}} src={"/images/infoIcon.svg"} alt={"Missing Image"}/>,
    className: "ongoing",
    text: "FÆRDIG",
    statusStyling: {color: "#777777", border: "1px solid #777777", background: "none"},
    statusHover: "#777777",
    ownColor: "#008DFF"
  },
  "AFVIST": {
    image: <img style={{height: "24px"}}  src={"/images/deniedIcon.svg"} alt={"Missing Image"}/>,
    imageBig: <img style={{height: "36px"}} src={"/images/deniedIcon.svg"} alt={"Missing Image"}/>,
    className: "rejected",
    ownColor: "#DF4848"
  },
  "FÆRDIG": {
    image: <img style={{height: "22px"}} src={"/images/checkmarkIcon.svg"} alt={"Missing Image"}/>,
    imageBig: <img style={{height: "33px"}} src={"/images/checkmarkIcon.svg"} alt={"Missing Image"}/>,
    className: "done",
    ownColor: "#777777"
  },
}

// Creating the context object and passing the default values.
const authContext = React.createContext({
  routePrefix: routePrefix,
  baseURL: baseURL,
  status: status,
  GetCookie: () => {},
  LogoutRequest: () => {},
  CloseAllModals: () => {},
});
  
export default authContext;