import React, {useEffect, useState, useContext} from "react";
import { useParams, useNavigate } from "react-router-dom";
import AppContext from "../appcontext"

const Booking = ({ firm, Logout }) => {
    const { id } = useParams();
    const [current, setCurrent] = useState({ status: "ACCEPTERET" })
    const [message, setMessage] = useState("");
    const [notes, setNotes] = useState([])  
    const [loading, setLoading] = useState(true)

    const app = useContext(AppContext)

    const navigate = useNavigate();

    // Associative Array to handle varying status of bookings

    const DeleteBooking = (id) => {
        // Create URL with Params
        let url = new URL(app.baseURL + "/deletebooking");
        let params = {
            
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to delete specified booking
        fetch(url, {
            credentials: "include",
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({id})
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }
            }
            navigate("/")
        })
    }

    const AddNote = (text) => {
        // Create URL with Params
        let url = new URL(app.baseURL + "/addnote");
        let params = {
            
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to add a note
        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,
                sender: firm.id,
                message: text ?? message
            })
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                navigate("/")
            }
        })
    }

    const StatusChange = (event) => {
        let bookingId = current.id

        var text = app.status[current.status].text

        setCurrent({...current, status: text})

        // Create URL with Params
        let url = new URL(app.baseURL + "/changestatus");
        let params = {
            
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to add a note
        fetch(url, {
            credentials: "include",
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: bookingId,
                status: text
            })
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                navigate("/")
            }
            if (text == "I GANG") {
                event.target.style.background = "#777777"
                event.target.style.color = "white"
            }
        })
        
    }

    const RejectBooking = () => {
        let bookingId = current.id
        let bookingStatus = "AFVIST"

        // Create URL with Params
        let url = new URL(app.baseURL + "/changestatus");
        let params = {
            
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to add a note
        fetch(url, {
            credentials: "include",
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: bookingId,
                status: bookingStatus
            })
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                navigate("/")
            }
            setCurrent({...current, status : bookingStatus})
        })
        
    }

    const ToggleDateEdit = () => {}

    const SwitchToDateInput = (e) => {
        let text = document.getElementById("bookingDateText")
        let input = document.getElementById("bookingDateInput")
        let submit = document.getElementById("bookingDateSubmit")
        let div = document.getElementById("bookingDateDiv")
        

        text.style.display = "none"
        input.style.display = "block"
        submit.style.display = "flex"
        div.style.display = "flex"
    }

    const SwitchToDateText = () => {
        let text = document.getElementById("bookingDateText")
        let input = document.getElementById("bookingDateInput")
        let submit = document.getElementById("bookingDateSubmit")
        let div = document.getElementById("bookingDateDiv")
        

        text.style.display = "flex"
        input.style.display = "none"
        submit.style.display = "none"
        div.style.display = "none"
    }

    const SwitchToTechnicianInput = () => {
        let text = document.getElementById("bookingTechnicianText")
        let input = document.getElementById("bookingTechnicianInput")
        let submit = document.getElementById("bookingTechnicianSubmit")
        let div = document.getElementById("bookingTechnicianDiv")
        

        text.style.display = "none"
        input.style.display = "block"
        submit.style.display = "flex"
        div.style.display = "flex"
    }

    const SwitchToTechnicianText = () => {
        let text = document.getElementById("bookingTechnicianText")
        let input = document.getElementById("bookingTechnicianInput")
        let submit = document.getElementById("bookingTechnicianSubmit")
        let div = document.getElementById("bookingTechnicianDiv")
        

        text.style.display = "flex"
        input.style.display = "none"
        submit.style.display = "none"
        div.style.display = "none"
    }

    const SwitchToExpandInput = () => {
        let text = document.getElementById("bookingExpandText")
        let input = document.getElementById("bookingExpandInput")
        let submit = document.getElementById("bookingExpandSubmit")
        let div = document.getElementById("bookingExpandDiv")
        

        text.style.display = "none"
        input.style.display = "block"
        submit.style.display = "flex"
        div.style.display = "flex"
    }

    const SwitchToExpandText = () => {
        let text = document.getElementById("bookingExpandText")
        let input = document.getElementById("bookingExpandInput")
        let submit = document.getElementById("bookingExpandSubmit")
        let div = document.getElementById("bookingExpandDiv")
        

        text.style.display = "flex"
        input.style.display = "none"
        submit.style.display = "none"
        div.style.display = "none"
    }

    const TextOnChange = (field) => {

        let split = field.split(";")
        let text = ""

        switch (split[0]) {
            case "DATE":
                text = "Dato skiftet til " + split[1]
                break;
            case "EXPAND":
                text = "Sagsbeskrivelse ændret"
            default:
                break;
        }


        // Get list of current ids
        let keys = notes.map(x => x.id);
        // Get highest id, and increment from it
        let incremented = Math.max(...keys) + 1;

        // Add a note to current list of notes
        setNotes([...notes, { id: incremented, message: text, sender: firm.id}])
        AddNote(text)
    }

    const UpdateDate = () => {
        let date = document.getElementById("bookingDateInput")

        let tempBooking = {...current}
        tempBooking.date = new Date(date.value)

        let url = new URL(app.baseURL + "/updatebooking");
        let params = {
            
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to add a note
        fetch(url, {
            credentials: "include",
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tempBooking)
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                navigate("/")
            }
            tempBooking.date = tempBooking.date.toLocaleString("da-DK", { dateStyle: "medium" })
            setCurrent(tempBooking)
            SwitchToDateText()
            TextOnChange("DATE;" + tempBooking.date)
        })
    }

    const UpdateTechnician = () => {
        let technician = document.getElementById("bookingTechnicianInput")

        let tempBooking = {...current}
        tempBooking.technician = technician.value

        let url = new URL(app.baseURL + "/updatebooking");
        let params = {
            
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to add a note
        fetch(url, {
            credentials: "include",
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tempBooking)
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                navigate("/")
            }
            setCurrent(tempBooking)
            SwitchToTechnicianText()
            TextOnChange("TECHNICIAN;" + tempBooking.technician)
        })
    }

    const UpdateExpand = () => {
        let expand = document.getElementById("bookingExpandInput")

        let tempBooking = {...current}
        tempBooking.expand = expand.value

        let url = new URL(app.baseURL + "/updatebooking");
        let params = {
            
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to add a note
        fetch(url, {
            credentials: "include",
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tempBooking)
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                navigate("/")
            }
            setCurrent(tempBooking)
            SwitchToExpandText()
            TextOnChange("EXPAND;" + tempBooking.expand)
        })
    }

    const RejectedMouseOver = (event) => {
        event.target.style.background = app.status[current.status].statusRejectedHover
        event.target.style.color = "white"
    }

    const RejectedMouseOut = (event) => {
        event.target.style.color = app.status[current.status].statusRejectedHover
        event.target.style.background = "none"
    }

    const MouseOver = (event) => {
        event.target.style.background = app.status[current.status].statusHover
        event.target.style.color = "white"
    }

    const MouseOut = (event) => {
        event.target.style.color = app.status[current.status].statusHover
        event.target.style.background = "none"

    }

    useEffect(() => {
        let noteBox = document.getElementById("notes");

        if (noteBox) {
            // By dependency, scroll the scrollable element to the bottom on every message
            noteBox.scrollTop = noteBox.scrollHeight
        }
    }, [notes])

    useEffect(() => {
        // Subscribe to useEffect to prevent Memory Leak
        let isSubscribed = true;

        // Create URL with Params
        let url = new URL(app.baseURL + "/booking/" + id);
        let params = {
            
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to get specified booking
        fetch(url, {
            credentials: "include",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(res => {
            if (isSubscribed) {
                if (!res.ok) {
                    // If the response is unauthorized
                    if (res.status === 401) {
                        // Delete current token by setting it to expire on a previous date
                        document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                        document.cookie = "LOGGEDIN= false"
                        Logout()
                        app.LogoutRequest()

                        navigate("/")
                    } else if (res.status === 404) {
                        navigate("/404")
                    } else {
                        // Push to refresh page
                        navigate("/")
                    }
                } else {
                    res.json().then(json => {
                        setLoading(false)
                        setCurrent(json[0])
                    })
                }
            }
        })

        // Create URL with Params
        url = new URL(app.baseURL + "/notes")
        params = {
            id: id
        }
        url.search = new URLSearchParams(params).toString()

        // Send Request to get all current notes
        fetch(url, {
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                if (res.status === 404) {
                    console.log("No Notes Found")
                }
                
            }
            else {
                res.json().then(json => {
                    setNotes(json)
                })
            }
        })
        

        // End subscription
        return () => {isSubscribed = false}
    }, [])

    return (
        <React.Fragment>
            {loading ? <div style={{height: "10000px"}}></div>  : <React.Fragment>
            <div style={{display: "flex", padding: "55px", justifyContent: "space-between" }}>
                <div className="buttons" id="backbuttonbook" onClick={(e) => {
                    // Prevent all default behaviour of Button
                    e.preventDefault();

                    // Go back one step in browser history
                    navigate(-1)
                }}>TILBAGE</div>
                <div style={{ display: "flex", flexDirection: "column"}}>
                    <h1 style={{ alignSelf: "center" }}>{current.title}</h1>
                    <div style={{ padding: "0px 150px", display: "flex", flexDirection: "column" }}>
                        <div style={{display: "flex", flexDirection: "row" }}>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column", gap: "16px", minWidth: "300px"}}>
                            <div>
                                <div style={{ fontSize: "15px", color: "rgb(120, 120, 120)" }}>Booker</div>
                                <div style={{ fontSize: "28px" }}>{current.booker}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: "15px", color: "rgb(120, 120, 120)" }}>Ønsket Dato</div>
                                    <form onSubmit={(event) => {
                                        event.preventDefault()
                                        UpdateDate()
                                    }}>
                                        <div style={{ fontSize: "28px", display: "flex" }} id="bookingDateText">
                                            {new Date(current.date).toLocaleString("da-DK", { dateStyle: "medium" })}
                                            {current.status === "AFVENTER" || current.status === "ACCEPTERET" && firm.id === "1" ? <img src={"/images/edit-pen.svg"} className="EditPen" onClick={SwitchToDateInput}/> : <></>}
                                        </div>
                                        <div id="bookingDateDiv" style={{ display: "flex", alignItems: "center", height: "32px", display: "none" }}>
                                            <input id="bookingDateInput" type="date" required style={{ display: "none", height: "32px" }} min={new Date().toISOString().split("T")[0]} max={new Date("31 December 9999").toISOString().split("T")[0]}/>
                                            <button id="bookingDateSubmit" type="submit" style={{ height: "32px", width: "32px", background: "none", justifyContent: "center", alignItems: "center" }}><img src={"/images/checkmarkBlack.svg"} style={{ height: "32px", paddingLeft: "20px" }}/></button>
                                        </div>
                                    </form>
                            </div>
                            </div>
                                <div id="bookingstatusbox">
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "180px"}}>
                                        <div style={{fontSize: "30px"}}>Status</div>
                                    </div>
                                    <div id="bookingstatus" className={app.status[current.status].className} style={{fontSize: "20px"}}>
                                        { app.status[current.status].imageBig }
                                        { current.status }
                                    </div>
                                </div>
                        </div>
                        <div style={{ marginTop: "16px" }}>
                            <div style={{ fontSize: "15px", color: "rgb(120, 120, 120)" }}>Addresse</div>
                            <div style={{ fontSize: "28px" }}>{current.address !== "" ? current.address : "MANGLER"}</div>
                        </div>
                        <div style={{ marginTop: "16px" }}>
                            <div style={{ fontSize: "15px", color: "rgb(120, 120, 120)" }}>Sagsbeskrivelse</div>
                            <form onSubmit={(event) => {
                                event.preventDefault()
                                UpdateExpand()
                            }}>
                                <div style={{ fontSize: "24px", maxWidth: "600px", display: "flex", flexDirection: "row" }} id="bookingExpandText">
                                    {current.expand !== "" ? current.expand : "MANGLER"}
                                    {current.status === "AFVENTER" ? <img src={"/images/edit-pen.svg"} className="EditPen" onClick={SwitchToExpandInput}/> : <></>}
                                </div>
                                <div id="bookingExpandDiv" style={{ display: "flex", alignItems: "center", display: "none" }}>
                                        <textarea id="bookingExpandInput" required style={{ display: "none", height: "200px" }} defaultValue={current.expand}/>
                                        <button id="bookingExpandSubmit" type="submit" style={{ height: "32px", width: "32px", background: "none", justifyContent: "center", alignItems: "center" }}><img src={"/images/checkmarkBlack.svg"} style={{ height: "32px", paddingLeft: "20px" }}/></button>
                                </div>
                            </form>
                        </div>

                        </div>
                        <div>
                            <div style={{ paddingLeft: "150px", width: "fit-content" }}>
                                <div style={{ fontSize: "13px", marginTop: "20px" }}>Kommunikation</div>
                                    <div id="notes" className="scroll" style={{ marginTop: "10px" }}>
                                        {notes.map(note => {
                                        let decidedStyle = firm.id == note.sender ? {
                                            background: "#008DFF",
                                            marginLeft: "auto",
                                            fontSize: "24px",
                                            maxWidth: "300px"
                                        } : {
                                            background: "#777777",
                                            marginRight: "auto",
                                            fontSize: "24px",
                                            maxWidth: "300px"
                                        };
                                            return <div key={note.id} style={{...decidedStyle, ...{
                                                marginTop: "5px",
                                                padding: "5px",
                                                color: "#FFFFFF",
                                                borderRadius: "10px"
                                            }}}>{note.message}</div>
                                        })}
                                    </div>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                
                                    // If textfield is empty
                                    if (message === "") return;

                                    // Get list of current ids
                                    let keys = notes.map(x => x.id);
                                    // Get highest id, and increment from it
                                    let incremented = Math.max(...keys) + 1;

                                    // Add a note to current list of notes
                                    setNotes([...notes, { id: incremented, message: message, sender: firm.id}])
                                    AddNote()

                                    // Empty the Textfield
                                    setMessage("")
                                    e.target.value = "";
                                }} style={{ display: "flex", gap: "16px", width: "500px" }}>
                                    <input onChange={(e) => {
                                        setMessage(e.target.value)
                                    }} type="text" style={{
                                        background: "#E8E8E8",
                                        borderRadius: "6px",
                                        marginTop: "20px",
                                        height: "36px"
                                    }} value={message} placeholder="Svar"/>
                                    <button type="submit" style={{
                                        background: "#008DFF",
                                        marginTop: "20px",
                                        height: "36px",
                                        width: "36px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0 15px" }
                                    }>{">"}</button>
                                </form>
                                {firm.id === "1" && current.status !== "FÆRDIG" ? <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    {current.status === "AFVENTER" ? <button onMouseOver={RejectedMouseOver} onMouseOut={RejectedMouseOut} style={app.status[current.status].statusRejectedStyling} onClick={RejectBooking}>AFVIST</button> : <></>}
                                    {current.status !== "AFVIST" ? <button onMouseOver={MouseOver} onMouseOut={MouseOut} style={app.status[current.status].statusStyling} onClick={StatusChange}>{app.status[current.status].text}</button> : <></> }
                                </div> : <></>}
                            </div>
                        </div>
                    </div>
                    <div className="buttons" id="deletebuttonbook" onClick={() => {
                        let modal = document.getElementById("confirmModal");
                        // Set confirmation modal to visible (From display: none;)
                        modal.style.display = "flex"
                    }}>Slet</div>
                </div>
                <div id="confirmModal" className="modal" onClick={(event) => {
                    // If the user clicks, check if they clicked the confirmation modal
                    if (event.target === document.getElementById("confirmModal")) {
                        // Make the confirmation modal invisible
                        document.getElementById("confirmModal").style.display = "none";
                    }
                }}>
                    {/* Modal content */}
                    <div id="confirmmodal-content">
                    <form className="modalforms" onSubmit={function(event){
                        event.preventDefault();
                        DeleteBooking(id);
                    }}>
                        <h1 className="modalh1">Slet Booking?</h1>
                        <div className="modalbuttonbox">
                            <button className="cancelmodalbutton" id="cancelremovebookmodal" type="button" onClick={app.CloseAllModals}>Anuller</button>
                            <button className="removemodalbutton" id="removebookmodalbutton" type="submit">Slet Booking</button>
                        </div>
                    </form>
                    </div>
                </div>
            
            </React.Fragment> }
        </React.Fragment>

    );
}

export default Booking;