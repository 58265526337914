import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom"
import AppContext from "../appcontext"

const BookModal = ({ firm, Logout }) => {
    const app = useContext(AppContext)
    const navigate = useNavigate()

    const storedFirmName = app.GetCookie("FIRMNAME")
    const storedFullname = app.GetCookie("FULLNAME")
    const storedEmail = app.GetCookie("EMAIL")
    const token = app.GetCookie("token")

    const [formData, setFormData] = useState({})
    const [remunerators, setRemunerators] = useState([]);
    const [userCvr, setUserCvr] = useState("")
    const [loading, setLoading] = useState(true)

    const HandleChange = event => {
        setFormData({...formData, [event.target.name]: event.target.value.trim()})
    }

    const SubmitNewBooking = (e) => {
        e.preventDefault();

        let url = new URL(app.baseURL + "/insertbooking")
        let params = {

        }
        url.search = new URLSearchParams(params).toString()

        var tempForm = {...formData}
        if (!formData.cvr || formData.cvr == "") {
            tempForm.cvr = userCvr.cvr
            setFormData(tempForm)
        }
        if (!formData.technician) {
            tempForm.technician = ""
        }

        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tempForm)
        })
        .then((res) => {
            if (!res.ok) {
                if (res.status == 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()

                    navigate("/")
                }
                if (res.status == 403) {
                    console.log(tempForm)
                    console.log("Missing CVR")
                }
            } else {

                var bookinginputs = document.querySelectorAll("#insertbookingform input");
                bookinginputs.forEach(x => {
                    x.value = "";
                    if (x.name === "email") x.value = storedEmail
                    if (x.name === "booker") x.value = storedFullname
                    if (x.name === "firm") x.value = storedFirmName
                })
                app.CloseAllModals()
            }
            window.location.href = "/"
            
        })
    }

    useEffect(() => {
        let isSubscribed = true;

        let url = new URL(app.baseURL + "/remunerators/")
        let params = {
            id: firm.id
        }
        url.search = new URLSearchParams(params).toString()

        fetch(url, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            }
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }
                setLoading(false)

                navigate("/")
            }
            return res.json()
        })
        .then(json => {
            if (isSubscribed) {
                if (json.error === "expiredtoken") {
                    navigate("/")
                } else {
                    setUserCvr(json[0])
                    setRemunerators(json)
                    setLoading(false)
                    setFormData({ 
                        ["email"]: storedEmail, 
                        ["booker"]: storedFullname, 
                        ["firm"]: storedFirmName,
                        ["cvr"]: json[0].cvr
                    })
                }
            }
        })


        
        // cancel subscription to useEffect
        return () => {isSubscribed = false}
    }, [])

    return (
        <div id="createBookingModal" className="modal" onClick={(event) => {
            if (event.target === document.getElementById("createBookingModal")) {
                var bookinginputs = document.querySelectorAll("#insertbookingform input");
                bookinginputs.forEach(x => {
                    x.value = "";
                    if (x.name === "email") x.value = storedEmail
                    if (x.name === "booker") x.value = storedFullname
                    if (x.name === "firm") x.value = storedFirmName
                })
                document.getElementById("createBookingModal").style.display = "none";
            }
        }}>
            {/* Modal content */}
            {loading ? <></> :
            <div id="bookmodal-content">
                <form id="insertbookingform" onSubmit={SubmitNewBooking} method="post" className="flexCenteredColumn modalforms">
                    <div className="container" style={{ width: "400px" }}>
                        <label><b>Booker</b>
                            <input type="text" onChange={HandleChange} name="booker" defaultValue={storedFullname} readOnly required/>
                        </label>

                        <label><b>Firma</b>
                            <input type="text" onChange={HandleChange} name="firm" defaultValue={storedFirmName} readOnly required/>
                        </label>

                        <label><b>Adresse</b>
                            <input type="text" onChange={HandleChange} name="address" placeholder="Skriv adresse på sagen" required/>
                        </label>

                        <label><b>Ønsket dato for udførelse</b>
                            <input type="date" onChange={HandleChange} name="date" id="datepicker" min={new Date().toISOString().split("T")[0]} max={new Date("31 December 9999").toISOString().split("T")[0]} required/>
                        </label>

                        <label><b>Sagsnavn</b>
                            <input type="text" onChange={HandleChange} name="title" placeholder="Skriv overskriften på sagen" required/>
                        </label>

                        <label><b>Sagsbeskrivelse</b>
                            <textarea onChange={HandleChange} name="expand" placeholder="Skriv længere beskrivelse af sagen her" required/>
                        </label>

                        <label><b>Betalende virksomhed</b>
                        {
                            remunerators.length < 2 ? <div>{userCvr.name}</div> : (
                                <select name="cvr" onChange={HandleChange} defaultValue="" required>
                                    <option disabled hidden value=" "/>
                                    {
                                        // Filter away irrelevant CVR's. Show all if KKPARTNER.
                                        remunerators.map((remunerator) => {
                                            return <option key={remunerator.id} value={remunerator.cvr}>{remunerator.name}</option>
                                        })
                                    }
                                </select>
                            )                          
                        }
                        </label>
                        <div style={{display: "flex", justifyContent: "center", paddingTop: "10px"}}>
                            <button className="cancelmodalbutton" id="cancelremovebookmodal" type="button" onClick={() => 
                            {  
                                var bookinginputs = document.querySelectorAll("#insertbookingform input");
                                bookinginputs.forEach(x => {
                                    x.value = "";
                                    if (x.name === "email") x.value = storedEmail
                                    if (x.name === "booker") x.value = storedFullname
                                    if (x.name === "firm") x.value = storedFirmName
                                })



                                app.CloseAllModals();
                            }}>Anuller</button>
                            <button className="bookmodalbutton" id="bookmodalbutton" type="submit">Book</button>
                        </div>
                    </div>
                </form>
            </div> }
        </div>
    )
}


export default BookModal;