import React, { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import AppContext from "../../appcontext"

const AddUser = ({ firms, Logout }) => {
    const app = useContext(AppContext)
    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [fullname, setFullname] = useState("")
    const [password, setPassword] = useState("")
    const [tele, setTele] = useState("")
    const [firmId, setFirmId] = useState("")

    const CreateUser = (event) => {
        // Prevent form from refreshing page
        event.preventDefault()

        // Temporary Object with Hook Values
        let tempUser = {
            email: email,
            fullname: fullname,
            password: password,
            tele: tele,
            firmid: firmId
        };

        // Create URL with Params
        let url = new URL(app.baseURL + "/adduser");
        let params = {
            
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to Add a User
        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tempUser)
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                navigate("/")
            }
        })
    }

    useEffect(() => {

    },[])

    return(
        <div style={{display: "flex", flexDirection: "column", border: "1px solid black", padding: "10px"}}>
           <h2>Tilføj Bruger</h2>
           <form style={{display: "flex", flexDirection: "column"}} onSubmit={(event) => {
               event.preventDefault()
               CreateUser(event)
               event.target.reset()}}>
               <label>Email</label>
               <input required onChange={(e) => setEmail(e.target.value)}/>

               <label>Navn</label>
               <input required onChange={(e) => setFullname(e.target.value)}/>

               <label>Adgangskode</label>
               <input required onChange={(e) => setPassword(e.target.value)}/>

               <label>Telefonnummer</label>
               <input required onChange={(e) => setTele(e.target.value)}/>

               <label>Firma</label>
               <select required defaultValue="" onChange={(e) => {setFirmId(e.target.value)}}>
                   <option value="" hidden disabled/>
                   {firms ? firms.map((firm) => {
                       return(
                           <option value={firm.id} key={firm.id}>{firm.name}</option>
                       )
                   }) : <React.Fragment></React.Fragment>}
               </select>
               <button type="submit">Tilføj</button>
           </form>
       </div>
     )
}
export default AddUser;