import React, { useEffect, useState, useContext } from "react"
import AppContext from "../appcontext"

const BookingBox = ({ booking, HandleBoxClick }) => {
    const [assignedDate, setAssignedDate] = useState("")
    const [title, setTitle] = useState("")
    const [cName, setCName] = useState("")
    const [image, setImage] = useState(<></>)
    const app = useContext(AppContext)

    useEffect(() => {
        var date = new Date(booking.date).toLocaleDateString("da-DK")
        setAssignedDate(date);

        if (app.status[booking.status]) {
            setImage(app.status[booking.status].image)
            setCName(app.status[booking.status].className)
        }

    
        // If the title is too long, cut it off and add dots
        setTitle(booking.title.length >= 20 ? booking.title.slice(0, 20) + "..." : booking.title);
    }, [])

    
    return (
        <div key={booking.id} className='bookingBox' onClick={() => HandleBoxClick(booking)}>
            <div style={{
                paddingLeft: "20px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
            }}>
                <div style={{ fontSize: "14px", color: "rgb(150, 150, 150)" }}>Sag</div>
                <div style={{ fontSize: "22px" }}>{title}</div>
                <br/>
                <div style={{ fontSize: "14px", color: "rgb(150, 150, 150)" }}>Booker</div>
                <img style={{ height: "24px", position: "absolute", paddingLeft: "250px"}} src={"/images/chevronIcon.svg"} alt={"Missing Image"}/>
                <div style={{ fontSize: "22px" }}>{booking.booker}</div>
                <br/>
                <div style={{ fontSize: "14px", color: "rgb(150, 150, 150)" }}>Ønsket Dato</div>
                <div style={{ fontSize: "22px" }}>{assignedDate}</div>
            </div>
            <div id="statusField" className={cName}>
                {image}
                { booking.status }
            </div>
        </div>
    )
}

export default BookingBox