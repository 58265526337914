import React, { useEffect, useState, useContext } from "react";
import AppContext from "../appcontext"
import { useParams, useNavigate } from "react-router-dom";


const Header = ({ Logout, loggedIn }) => {

    const app = useContext(AppContext)

    const LogoutRequest = () => {
        

        let isSubscribed = true

        // Create URL with Params
        let url = new URL(app.baseURL + "/logout");

        // Send Request to get specified booking
        fetch(url, {
            credentials: "include",
            method: "POST"
        }).then(res => {
            if (isSubscribed) {
                if (!res.ok) {
                    // If the response is unauthorized
                    if (res.status === 401) {
                        // Delete current token by setting it to expire on a previous date
                        document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                        document.cookie = "LOGGEDIN= false"
                        Logout()
                        app.LogoutRequest()

                    } else if (res.status === 404) {
                        window.location.href = "/404"
                    } else {
                        // Push to refresh page
                        window.location.hrer = "/"
                    }
                }
            }
            document.cookie = "FIRM=0; SameSite=Lax; path=/; expires= Thu, 21 Aug 2014 20:00:00 UTC";
            document.cookie = "FIRMNAME=0; SameSite=Lax; path=/; expires= Thu, 21 Aug 2014 20:00:00 UTC";
            document.cookie = "FULLNAME=0; SameSite=Lax; path=/; expires= Thu, 21 Aug 2014 20:00:00 UTC";
            document.cookie = "EMAIL=0; SameSite=Lax; path=/; expires= Thu, 21 Aug 2014 20:00:00 UTC";
            document.cookie = "LOGGEDIN=false";
            window.location.href = "/"
        })
        isSubscribed = false
    }

    return (
        <header className="header" style={{ height: "89px" }}>
            <div className="header__inner">
                <div className="grid-x grid-margin-x align-justify align-middle" style={{ height: "26px" }}>
                    <div className="cell shrink">
                        <a className="logo" href="https://kkpartner.dk">
                            <img src={"/images/logo.svg"} alt="Logo" style={{ marginTop: "1px" }}></img>
                        </a>
                    </div>
                    <div className="cell shrink">
                        <div className="header__nav flex-container align-middle align-right">
                            <ul className="service-nav list-base show-for-large">
                                <li className="service-nav__item link">
                                    <a className="service-nav__link link" href="https://www.kkpartner.dk/iot">
										IoT
                                    </a>
                                    <div className="service-nav__children flex-container">
                                        <div className="flex-child-auto">
                                            <div className="service-nav__sub-title">IoT</div>
                                            <ul className="child-nav">	
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/iot/wifi-and-netvaerk/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Wifi-netvaerk.svg"} alt="Wifi netvaerk" width="36" height="36"></img>
                                                        </span>Wifi &amp; Netværk
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/iot/akustik/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Praesentationsystemer.svg"} alt="Praesentationsystemer" width="36" height="36"></img>
                                                        </span>Akustik
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/iot/av-and-it/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Konferencesystem.svg"} alt="Konferencesystem" width="36" height="36"></img>
                                                        </span>AV &amp; IT
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/iot/dorlasesystemer/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Komplette-losninger.svg"} alt="Komplette losninger" width="36" height="36"></img>
                                                        </span>Dørlåsesystemer 
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/iot/videoovervagning/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Radgivning-v3.svg"} alt="Radgivning v3" width="36" height="36"></img>
                                                        </span>Videoovervågning
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/iot/softwareudvikling/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/IOT-Fremtidssikret-v2.svg"} alt="IOT Fremtidssikret v2" width="36" height="36"></img>
                                                        </span>Softwareudvikling
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/iot/komponent-reparation/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Komponent-reparation.svg"} alt="Komponent reparation" width="36" height="36"></img>
                                                        </span>Komponent reparation
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="flex-child-shrink">
                                            <div className="service-nav__children-text">
                                                <h2>Vil du vide mere?</h2><p>Har du spørgsmål så kontakt Os på tlf. <a href="tel:+4575381414">75 38 14 14</a> for at høre mere eller booke et møde.</p>
                                            </div>
                                        </div>
                                    </div>
								</li>
                                <li className="service-nav__item link">
                                    <a className="service-nav__link link" href="https://www.kkpartner.dk/el">
										EL
                                    </a>
									<div className="service-nav__children flex-container">
                                        <div className="flex-child-auto">
                                            <div className="service-nav__sub-title">EL</div>
                                            <ul className="child-nav">
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/el/cts-and-bsm-systemleverandor/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/GTS-BSM-systemleverandor.svg"} alt="GTS BSM systemleverandor" width="36" height="36"></img>
                                                        </span>CTS &amp; BSM systemleverandør
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/el/komplet-el-losning/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Komplet-el-losning.svg"} alt="Komplet el losning" width="36" height="36"></img>
                                                        </span>Eltekniske installationer
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/el/sikringspakken-aia-itv-abv/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Sikringspakken-AIA-ITV-ABV.svg"} alt="Sikringspakken AIA ITV ABV" width="36" height="36"></img>
                                                        </span>Sikringspakken AIA, ITV, ABV
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/el/energioptimering/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Energioptimering.svg"} alt="Energioptimering" width="36" height="36"></img>
                                                        </span>Energioptimering
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/el/smarthome/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/smartHouse.svg"} alt="smartHouse" width="36" height="36"></img>
                                                        </span>SmartHome
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="flex-child-shrink">
                                            <div className="service-nav__children-text">
                                                <h2>Vil du vide mere?</h2><p>Har du spørgsmål så kontakt Os på tlf. <a href="tel:+4575381414">75 38 14 14</a> for at høre mere eller booke et møde.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="service-nav__item link">
                                    <a className="service-nav__link link" href="https://www.kkpartner.dk/kabel-tv">
										Kabel TV
                                    </a>
									<div className="service-nav__children flex-container">
                                        <div className="flex-child-auto">
                                            <div className="service-nav__sub-title">Kabel TV</div>
                                            <ul className="child-nav">
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/kabel-tv/komplette-fiberlosninger/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Komplettefiberlosning.svg"} alt="Komplettefiberlosning" width="36" height="36"></img>
                                                        </span>Komplette løsninger
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/kabel-tv/dokumentation/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Dokumentation.svg"} alt="Dokumentation" width="36" height="36"></img>
                                                        </span>Dokumentation &amp; LER
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/kabel-tv/projektering-and-dimensionering/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Projektering-dimensionering.svg"} alt="Projektering dimensionering" width="36" height="36"></img>
                                                        </span>Projektering &amp; Beregning
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/kabel-tv/support-af-internet-and-kabel-tv/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Support-internet-kabel-tv.svg"} alt="Support internet kabel tv" width="36" height="36"></img>
                                                        </span>Support, service &amp; vagt
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/kabel-tv/opdateret-instrumentpakke/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Opdateret-instrumentpakke.svg"} alt="Opdateret instrumentpakke" width="36" height="36"></img>
                                                        </span>Instrumenter &amp; fiber udstyr
                                                    </a>
                                                </li>
                                                <li className="child-nav__item link">
                                                    <a href="https://kkpartner.dk/kabel-tv/radgivning/" className="child-nav__link  link">
                                                        <span className="child-nav__icon">
                                                            <img src={"/images/Radgivning.svg"} alt="Radgivning" width="36" height="36"></img>
                                                        </span>Rådgivning
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="flex-child-shrink">
                                            <div className="service-nav__children-text">
                                                <h2>Vil du vide mere?</h2><p>Har du spørgsmål så kontakt os på tlf. <a href="tel:+4575381414">75 38 14 14</a> for at høre mere eller booke et møde.</p>
                                            </div>
                                        </div>
                                    </div>
								</li>
                            </ul>
                            { loggedIn ? <a id="logout" onClick={() => {
                                Logout()
                                LogoutRequest()
                            }}>Log ud</a> : <></> }
                        </div>
                    </div>
                </div>
                <div className="hide"></div>
            </div>
        </header>        
    );
}

export default Header;