import React, {useState, useContext} from 'react';
import BookingBox from "./BookingBox.jsx"
import AppContext from "../appcontext"

const SortingArray = ({bookings, setBookings, HandleBoxClick, filtered, setFiltered}) => {
    const [sortState, setSortState] = useState("first")
    const [bookingsText, setBookingsText] = useState("Alle Sager")
    const [lastFiltered, setLastFiltered] = useState("")

    const app = useContext(AppContext)

    const FilterStatusText = (status) => {
        switch(status) {
            case "AFVENTER":
                setBookingsText("Afventende Sager")
                break;
            case "ACCEPTERET":
                setBookingsText("Accepterede Sager")
                break;
            case "I GANG":
                setBookingsText("Igangværende Sager")
                break;
            case "AFVIST":
                setBookingsText("Afviste Sager")
                break;
            case "FÆRDIG":
                setBookingsText("Færdige Sager")
                break;
            default:
                setBookingsText("Alle Sager")
                break;
        }
    }
    
    const FilterClick = (event) => {
        var filterButton = event.target;
        var status = filterButton.innerHTML

        if (filtered === true) {
            if(lastFiltered === status) {
                setFiltered(!filtered)
                setBookingsText("Alle Sager")
                filterButton.style.background = "none"
                filterButton.style.color = app.status[status].ownColor
                return
            } else {
                FilterStatusText(status)
            }
        } else {
            setFiltered(true)
            FilterStatusText(status)
        }
        
        setLastFiltered(status)

        var children = document.getElementById("sortBoxes").children
        for (var child of children) {
            child.style.background = "none"
            child.style.color = app.status[child.innerHTML].ownColor
        }
        filterButton.style.background = app.status[status].ownColor
        filterButton.style.color = "white"
    }

    const SortByDate = () => {
        let tempbookings = [...bookings]
        if (sortState === "first") {
            tempbookings.sort((a, b) => a.created > b.created ? 1 : a.created < b.created ? -1 : 0)
            setSortState("last")
        } else if (sortState === "last") {
            tempbookings.sort((a, b) => a.created < b.created ? 1 : a.created > b.created ? -1 : 0)
            setSortState("first")
        }

        setBookings(tempbookings)
    }

    const sortArray = ["AFVENTER", "ACCEPTERET", "I GANG", "FÆRDIG", "AFVIST"]

    return (
        <React.Fragment>
            <h1 style={{ alignSelf: "center" }}>{bookingsText}</h1>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight: "150px", marginLeft: "150px"}}>
                <button id="sortButton" onClick={SortByDate}>Sorter nyeste{
                    sortState === "first" ? <img style={{ height: "30px" }} src={"/images/down-arrow.png"} alt="Missing image"/> 
                    : sortState === "last" ? <img style={{ height: "30px" }} src={"/images/up-arrow.png"} alt="Missing image" /> 
                    : <></>}
                </button>
                <div id="sortBoxes">
                    {sortArray.map((button) => {
                        var borderColor = app.status[button].ownColor
                        return(
                            <button onClick={FilterClick} style={{
                                borderRadius: "18px", 
                                border: "1px solid " + borderColor, 
                                background: "none", 
                                color: borderColor
                            }}>{button}</button>
                        )
                    })}
                </div>
            </div>
            <div className="wrapper">
                {bookings
                    .filter(x => {
                        var noFilterApplied = lastFiltered === "" || filtered === false
                        var bookingActive = x.status !== "FÆRDIG";
                        var alreadyFiltered = x.status === lastFiltered;
                        
                        return noFilterApplied ? bookingActive : alreadyFiltered
                    })
                    .map(booking => <BookingBox key={booking.id} booking={booking} HandleBoxClick={HandleBoxClick}/> )}
            </div>
                            
        </React.Fragment>
    )
}

export default SortingArray;