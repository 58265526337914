import React, { useEffect, useState, useContext } from "react";
import {BrowserRouter as Router, Redirect, Route, Routes} from 'react-router-dom';
import AppContext from "./appcontext"

import "./resources/index.css";

import Header from "./components/Header"
import Footer from "./components/Footer"
import Book from "./components/BookModal"
import Bookings from "./components/Bookings"
import Booking from "./components/Booking"
import Login from "./components/Login"
import AddClient from "./components/AddClient/index"
import NotFound from './components/NotFound';


const App = () => {
  const [authorized, setAuthorized] = useState(false)
  const [firm, setFirm] = useState({})
  const [fullName, setFullName] = useState("")
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)

  const app = useContext(AppContext)


  const GetCookie = (query) => {
    let cookies = document.cookie.split("; ");

    let values = {};
    cookies.forEach(cookie => {
      let KeyValuePair = cookie.split("=");

      let key = KeyValuePair[0];
      values[key] = KeyValuePair[1]
    })
    return values[query]
  }

  const LogoutRequest = () => {
        

    let isSubscribed = true
  
    // Create URL with Params
    let url = new URL(app.baseURL + "/logout");
  
    // Send Request to get specified booking
    fetch(url, {
        credentials: "include",
        method: "POST"
    }).then(res => {
        if (isSubscribed) {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete current token by setting it to expire on a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                    document.cookie = "LOGGEDIN= false"
                    window.location.href = "/react"
  
                } else if (res.status === 404) {
                    window.location.href = "/react/404"
                } else {
                    // Push to refresh page
                    window.location.href = "/react"
                }
            }
        }
        document.cookie = "FIRM=0; SameSite=Lax; path=/; expires= Thu, 21 Aug 2014 20:00:00 UTC";
        document.cookie = "FIRMNAME=0; SameSite=Lax; path=/; expires= Thu, 21 Aug 2014 20:00:00 UTC";
        document.cookie = "FULLNAME=0; SameSite=Lax; path=/; expires= Thu, 21 Aug 2014 20:00:00 UTC";
        document.cookie = "EMAIL=0; SameSite=Lax; path=/; expires= Thu, 21 Aug 2014 20:00:00 UTC";
        document.cookie = "LOGGEDIN=false";
        window.location.href = "/react"
    })
    isSubscribed = false
  }

  const Logout = () => {
    setAuthorized(false)
    setFirm({})
    setFullName("")
    setLoggedIn(false)
  }
  
  const CloseAllModals = () => {
    let modals = document.querySelectorAll(".modal");

    for (let i = 0; i < modals.length; i++) {
        const modal = modals[i];
        modal.style.display = "none"
    }    
  }

  useEffect(() => {

    if(GetCookie("LOGGEDIN") == "true") {
      setLoggedIn(true)
    }

    var tempfirm = {
      id: GetCookie("FIRM"), 
      firmname: GetCookie("FIRMNAME")
    }

    setFirm(tempfirm)
    setFullName(GetCookie("FULLNAME"))


  
  }, [])

  
  var loggedInToken = GetCookie("LOGGEDIN")

  if (loggedInToken == "true" || loggedIn == true) {  
    return (
      <React.Fragment>
        <Header Logout={Logout} firm={firm.name} fullName={fullName} authorized={authorized} loggedIn={loggedIn} /> 
        <Router>
          <AppContext.Provider value={{ routePrefix: app.routePrefix, baseURL: app.baseURL, status: app.status, GetCookie, firm, CloseAllModals }}>
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Bookings firm={{id: GetCookie("FIRM"), firmname: GetCookie("FIRMNAME")}} setMainLoading={setLoading} loading={loading} Logout={Logout} />} />
              <Route path="/book" element={<Book firm={firm} Logout={Logout} />} />
              <Route path="/addclient" element={<AddClient firm={firm} Logout={Logout} />} />
              <Route path="/booking/:id" element={<Booking firm={firm} Logout={Logout} />} />
            </Routes>
          </AppContext.Provider>
        </Router>
        {!loading ? <Footer /> : <></>}
      </React.Fragment>
    );
  }

  return <Login setAuthorized={setAuthorized} setFirm={setFirm} setFullName={setFullName} setLoggedIn={setLoggedIn} Logout={Logout}/>
}

export default App;
