import React, {useContext, useState} from "react";
import AppContext from "../appcontext"
import Header from "./Header.jsx"
import Footer from "./Footer.jsx"

const Login = ({ setAuthorized, setFirm, setFullName, setAccessToken, setLoggedIn, Logout }) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    const app = useContext(AppContext)
    

    const HandleLogin = (e) => {
        e.preventDefault();

        // Send Request to acquire authenticate token, and refreshing token
        fetch(app.baseURL + "/auth", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }
                
                // HANDLE ERROR IN LOGIN
                setError("FORKERT LOGIN. GLEMT LOGIN? SÅ KONTAKT KK PARTNER")
            }
             else {             
                res.json().then((json) => {
                    // Destructure data from json
                    const { firmid, firmname, fullname, token } = json;

                    document.cookie = "FIRM=" + firmid + ";SameSite=Lax; max-age=3600; path=/;";
                    document.cookie = "FIRMNAME=" + firmname + ";SameSite=Lax; max-age=3600; path=/;";
                    document.cookie = "FULLNAME=" + fullname + ";SameSite=Lax; max-age=3600; path=/;";
                    document.cookie = "EMAIL=" + email + ";SameSite=Lax; max-age=3600; path=/;";
                    document.cookie = "LOGGEDIN=true";

                    setLoggedIn(true)

                    window.location = window.location
                })
            }
        })
    }




    return (
        <React.Fragment>
            <Header />
            <main>
                <img src={"/images/biglogo.svg"} style={{ height: "100px", display: "flex", margin: "auto", marginTop: "140px", marginBottom: "40px" }}></img>
                <form onSubmit={HandleLogin} method="post" className="flexCenteredColumn">
                    <label>{error}</label> 
                    <div className="container">
                        <label><b>Email</b>
                            <input type="text" name={email} onChange={(e) => {
                                setEmail(e.target.value)
                            }} required/>
                        </label>

                        <label><b>Kode</b>
                            <input type="password" name={password} onChange={(e) => {
                                setPassword(e.target.value)
                            }} required/>
                        </label>

                        <button type="submit">Log ind</button>          
                    </div>
                </form>
            </main>
            <Footer />
        </React.Fragment>
    )
}

export default Login;