import React, { useContext, useState } from 'react';
import AppContext from "../appcontext"

const FinishedBookings = ({bookings, HandleBoxClick, filtered, firms, firm}) => {
    const app = useContext(AppContext)

    const [clickedFirm, setClickedFirm] = useState("")
    const [firmText, setFirmText] = useState("")
    const [firmFiltered, setFirmFiltered] = useState(false)

    const FilterClick = (event, firm) => {
        var filterButton = event.target
        var status = firm.id

        if (firmFiltered == true) {
            if(clickedFirm == status) {
                setFirmFiltered(false)
                setFirmText("")
                setClickedFirm("")
                event.target.style.background = "#1A1B4E"
                event.target.style.border = "2px solid #1A1B4E"
                event.target.style.color = "white"
                return
            } else {
                setFirmText(firm.name)
                
            }
        } else {
            setFirmFiltered(true)
            setFirmText(firm.name)
            
        }
        
        setClickedFirm(status)
        var children = event.target.parentNode.children
        for (var child of children) {
            child.style.background = "#1A1B4E"
            child.style.color = "white"
            child.style.border = "2px solid #1A1B4E"
        }
        event.target.style.background = "none"
        event.target.style.color = "#1A1B4E"
        event.target.style.border = "2px solid #1A1B4E"
    }
    
    return (
        <div className="wrapper" style={{flexDirection: "column"}}>
            
            {filtered === false && bookings.some(booking => booking.status === "FÆRDIG") ? <h1 style={{ alignSelf: "center" }}>Færdige Sager</h1> : <></>}
            <div style={{display: "flex", justifyContent: "center", gap: "10px", flexWrap: "wrap"}}>
                {firm.id === "1" ? firms.map(firm => {
                    return (
                        <button style={{width: "150px", height: "100px"}} onClick={(event) => {FilterClick(event, firm)}}>{firm.name}</button>
                    )
                }): <></>}
            </div>
            <div className="wrapper">
                {bookings.filter(x => {
                    var noFilterApplied = clickedFirm == "" || firmFiltered == false
                    var bookingFinished = x.status == "FÆRDIG";
                    var alreadyFiltered = x.firm == clickedFirm && x.status == "FÆRDIG";

                    return noFilterApplied ? bookingFinished : alreadyFiltered
                }).map(booking => {
                    
                    // If the title is too long, cut it off and add dots
                    let title = booking.title.length >= 20 ? booking.title.slice(0, 20) + "..." : booking.title;

                    return <div key={booking.id} className='bookingBoxDone' onClick={() => HandleBoxClick(booking)}>
                        <div style={{
                            paddingLeft: "20px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                            <div style={{ fontSize: "14px", color: "rgb(150, 150, 150)" }}>Sag</div>
                            <div style={{ fontSize: "22px" }}>{title}</div>
                            <img style={{ height: "24px", position: "absolute", paddingLeft: "250px"}} src={"/images/chevronIcon.svg"} alt={"Missing Image"}/>
                        </div>
                        <div id="statusField" className={app.status[booking.status].className}>
                            { app.status[booking.status].image }
                            { booking.status }
                        </div>
                    </div>
                })}
            </div>
           
        </div>
    )
    
}

export default FinishedBookings;