import React, {useState, useEffect, useContext} from "react"
import { useNavigate } from "react-router-dom"

import AddFirm from "./AddFirm"
import AddRemunerator from "./AddRemunerator"
import AddUser from "./AddUser"
import AppContext from "../../appcontext";

const AddClient = ({ firm, Logout }) => {
    const app = useContext(AppContext)
    const navigate = useNavigate()

    const [firms, setFirms] = useState([])

    useEffect(() => {
        // Subscribe to useEffect to prevent memory leak
        let isSubscribed = true;

        // Create URL with Params
        let url = new URL(app.baseURL + "/firms");
        let params = {
            
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to get all firms
        fetch(url, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => {
            if (isSubscribed) {
                if (!res.ok) {
                    // If response is unauthorized
                    if (res.status === 401) {
                        // Delete the current token by setting it to a previous date
                        document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                        document.cookie = "LOGGEDIN= false"
                        Logout()
                        app.LogoutRequest()
                    }
                    
                } else {
                    res.json()
                        .then(json => {
                            setFirms(json)

                            // End subscription to prevent memory leak
                            isSubscribed = false;
                        })
                }
            }
        })

        return () => { isSubscribed = false }
    }, [])

    return (
        <React.Fragment>
            { firm ?
                <div style={{display: "flex", gap: "50px", padding: "55px"}}>
                    <div className="buttons" id="backbuttonclient" onClick={(e) => {
                        e.preventDefault();

                        navigate(-1)

                    }}>TILBAGE</div>
                    <div id="addclient">
                        <AddFirm firms={firms} setFirms={setFirms} Logout={Logout}/>
                        <AddUser firms={firms} Logout={Logout}/>
                        <AddRemunerator firms={firms} Logout={Logout}/>
                    </div>
                </div>
                : <h1>Godt forsøg</h1>
            }
        </React.Fragment>
    )
}
export default AddClient;
