import React, { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import AppContext from "../../appcontext"

const AddRemunerator = ({ firms, Logout }) => {
    const app = useContext(AppContext)
    const navigate = useNavigate()

    const [cvr, setCvr] = useState()
    const [cvrName, setCvrName] = useState("")
    const [firmId, setFirmId] = useState()

    const CheckCvr = (cvr) => {
        // CVRs are always 8 digits
        if(cvr.length === 8) {
            GetCvr(cvr)
        }
        else {
            setCvrName("")
        }
    }

    const GetCvr = (cvr) => {
        // Create URL with Params
        let url = new URL("https://cvrapi.dk/api");
        let params = {
            search: cvr,
            country: "DK"
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to get CVR Data
        fetch(url, {
            method: "GET"
        }).then(res => {
            res.json()
            .then(json => {
                setCvrName(json.name)
                setCvr(cvr)
            })
        })

    }

    const CreateRemunerator = (event) => {
        event.preventDefault()
        // Temporary Objecy with Hook Values
        let tempRemunerator = {
            name: cvrName,
            cvr: cvr,
            firmid: firmId
        };

        // Create URL with Params
        let url = new URL(app.baseURL + "/addremunerator");
        let params = {
            
        };
        url.search = new URLSearchParams(params).toString()

        // Send Request to Add a Remunerator
        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tempRemunerator)
        })
        .then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                navigate("/")
            }
        })
    }

    useEffect(() => {
    },[])
            

    return(
        <div style={{display: "flex", flexDirection: "column", border: "1px solid black", padding: "10px"}}>
            <h2>Tilføj CVR</h2>
            <form style={{display: "flex", flexDirection: "column"}} onSubmit={(event) => {
                event.preventDefault()
                CreateRemunerator(event)
                event.target.reset()}}>
                <select required defaultValue="" onChange={(e) => {setFirmId(e.target.value)}}>
                    <option value="" hidden disabled/>
                    {firms ? firms.map((firm) => {
                        return(
                            <option value={firm.id} key={firm.id}>{firm.name}</option>
                        )
                    }) : <React.Fragment></React.Fragment>}
                </select>
                <label>CVR</label>
                <input required onChange={(e) => CheckCvr(e.target.value)}/>
                <label>Navn</label>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <input defaultValue={cvrName} onChange={(e) => setCvrName(e.target.value)}/>
                    <div>{cvrName}</div >
                </div>
                <button type="submit">Tilføj</button>
            </form>
        </div>
    )
}
export default AddRemunerator