import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {

  useEffect(() => {
    setTimeout(() => {
      window.location = "http://booking.kkpartner.dk/"
    }, 3000)
  }, [])

  return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "400px"}}>
    <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link>
  </div>
};

export default NotFound;