import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom"
import AppContext from "../appcontext"

import BookModal from "./BookModal.jsx"
import SortableArray from "./SortableArray.jsx"
import MissingBookings from "./MissingBookings.jsx"
import FinishedBookings from "./FinishedBookings.jsx"


const Bookings = ({ firm, Logout, setMainLoading }) => {
    const [loading, setLoading] = useState(true);
    const [bookings, setBookings] = useState([]);
    const [originalBookings, setOriginalBookings] = useState([])
    const [filtered, setFiltered] = useState(false);
    const [firms, setFirms] = useState([])

    const app = useContext(AppContext)
    const navigate = useNavigate()

    const newClientClick = () => {
        // Push to refresh page
        navigate("/addclient")
    }

    const HandleBoxClick = (booking) => {
        // Push to refresh page
        navigate("/booking/" + booking.id)
    }

    const Search = (event) => {
        var text = event.target.value
        var tempbookings = [...originalBookings]
        if(text && text.length > 0) {
            setBookings(tempbookings.filter((x) => {
                let title = x.title.toLowerCase()
                let booker = x.booker.toLowerCase()

                return title.includes(text.toLowerCase()) || booker.includes(text.toLowerCase())
            }))
        } else {
            setBookings(originalBookings)
        }
    }

    useEffect(() => {
        // Subscribe to useEffect to prevent memory leak
        let isSubscribed = true;
        // Create URL with Params
       
        // On load, if the interval is going, clear it to remove duplications
        clearInterval(window.spinningInterval)

        // Start interval, with name
        window.spinningInterval = setInterval(() => {
            let icons = document.querySelectorAll(".spinning");

            // Remove "spinningicon" class from all icons
            for (const icon of icons) {
                icon.classList.remove("spinningicon")
            }

            // After 200ms, add "spinningicon" class to all icons that need to spin
            setTimeout(() => {
                var delay
                
                for (const icon of icons) {
                    delay = Math.floor(Math.random() * (2000 - 0) + 0);
                    setTimeout(() => icon.classList.add("spinningicon"), delay)
                }

            }, 200)

            // Repeats every 4 seconds
        }, 4000);

        // cancel subscription to useEffect
        return () => { isSubscribed = false; }
        
    }, [])

    useEffect(() => {
        let url = new URL(app.baseURL + "/bookings");
        let params = {
            id: firm.id
        };
        url.search = new URLSearchParams(params).toString()

        // Send request to get all bookings
        fetch(url, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                navigate("/")
            } else {
                res.json()
                .then(json => {
                    json.sort((a, b) => a.created < b.created ? 1 : a.created > b.created ? -1 : 0)

                    setBookings(json)
                    setOriginalBookings(json)
                })
            }
        })

        url = new URL(app.baseURL + "/firms");

        fetch(url, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                navigate("/")
            } else {
                res.json()
                .then(json => {
                    setFirms(json)
                })
            }
        })
        setMainLoading(false)
        setLoading(false)
    }, [])
    
    return (    
        <React.Fragment>
            {!loading ?
                <div className="wrapper" style={{ display: "flex", padding: "25px", flexDirection: "column" }}>
                    <div id="searchBox">
                        <img src="/images/magnifying-glass-gray.svg" style={{ height: "40px" }} />
                        <input type="text" autoComplete="off" placeholder="Søg" id="searchBar" onChange={Search} />
                    </div>
                    <div id="boxbuttons">
                        <div className="buttons" id="backbuttonboxes" onClick={() => {
                            let modal = document.getElementById("createBookingModal");
                            modal.style.display = "flex"
                        }}>Ny Booking</div>
                        {firm.id === "1" ? <div className="buttons" id="createbuttonboxes" onClick={newClientClick}>Opret ny kunde</div> : <></>}
                    </div>
                    {bookings && bookings.length > 0 ? (
                        <div style={{ display: "flex", flexDirection: "column" }}>                                                           
                            <SortableArray 
                                HandleBoxClick={HandleBoxClick}
                                filtered={filtered}
                                setFiltered={setFiltered}
                                setBookings={setBookings}
                                bookings={bookings} />
                            {!filtered ? <FinishedBookings
                                HandleBoxClick={HandleBoxClick}
                                filtered={filtered}
                                setBookings={setBookings}
                                bookings={bookings}
                                firms={firms}
                                firm={firm} /> : <React.Fragment></React.Fragment>}
                        </div>
                    ) : <MissingBookings />}
                    <BookModal firm={firm} Logout={Logout} />
                </div>
            : <div style={{height: "10000px"}}></div> } 
        </React.Fragment>
        
    )
}

export default Bookings;