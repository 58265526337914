import React from 'react';

const MissingBookings = () => {

    return (
        <div style={{ display: "flex" , marginLeft: "100px", paddingBottom: "100px" }}>
            <img src={"/images/arrowIcon.svg"} style={{ transform: "scaleX(-1)", height: "400px" }}/>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "300px" }}>
                <p style={{ fontSize: "36px" }}>Der er ingen sager endnu</p>
                <p style={{ fontSize: "36px" }}>Opret din sag ved at trykke på knappen</p>
            </div>
        </div>
    )
}

export default MissingBookings;