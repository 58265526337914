import React from "react";
import Logo from ".."

const Footer = () => {
    return(
        <div className="js-footer" style={{ marginTop: "0.15625px" }}>
		    <div className="pre-footer"></div>
            <footer className="footer">
                <div className="grid-container">
                    <div className="grid-x grid-margin-x">
                        <div className="cell medium-4 large-3">
                            <div className="footer-column-title">KK Partner</div>
                            <ul className="footer-item-list menu vertical">
                                <li className="footer-item-list__item">
                                    <a className="link" href="https://www.kkpartner.dk/kabel-tv/">Kabel TV</a>
                                </li>
                                <li className="footer-item-list__item">
                                    <a className="link" href="https://www.kkpartner.dk/el/">EL</a>
                                </li>
                                <li className="footer-item-list__item">
                                    <a className="link" href="https://www.kkpartner.dk/iot/">IOT</a>
                                </li>
                                <li className="footer-item-list__item">
                                    <a className="link" href="https://www.kkpartner.dk/om-os/">Om os/Værdier</a>
                                </li>
                                <li className="footer-item-list__item">
                                    <a className="link" href="https://www.kkpartner.dk//kontakt-os/">Kontakt</a>
                                </li>
                            </ul>
                        </div>
                        <div className="cell medium-4 large-3">
                            <div className="footer-column-title">Andet</div>
                            <ul className="footer-item-list list-base list-base--vertical">
                                <li className="footer-item-list__item">
                                    <a className="link" href="https://www.kkpartner.dk/">CSR</a>
                                </li>
                                <li className="footer-item-list__item">
                                    <a className="link" href="https://www.kkpartner.dk/om-os/mission/">Mission</a>
                                </li>
                            </ul>
                        </div>
                        <div className="cell medium-4 large-3">
                            <div className="footer-column-title">Sociale medier</div>
                            <ul className="footer-item-list list-base list-base--vertical">
                                <li className="footer-item-list__item">
                                    <a className="link" href="https://www.linkedin.com/company/kk-partner-aps/" target="_blank">Linkedin </a>
                                </li>
                                <li className="footer-item-list__item">
                                    <a className="link" href="https://www.facebook.com/kkpartneraps" target="_blank">Facebook</a>
                                </li>
                            </ul>
                        </div>
                        <div className="cell medium-4 large-3">
                            <div className="footer-column-title">
                                <a className="logo-footer" href="/">
                                    <img src={"/images/logo.svg"} alt="Logo"></img>
                                </a>
                            </div>
                            <ul className="footer-item-list list-base list-base--vertical">
                                <li className="footer-item-list__item">Stensvang Allé 4</li>
                                <li className="footer-item-list__item">6650  Brørup</li>
                                <li className="footer-item-list__item">CVR: 28860439</li>
                                <li className="footer-item-list__item">
                                    <a className="footer-contacts__link" href="tel:+45-75-38-14-14">Tlf.: 75 38 14 14</a>
                                </li>
                                <li className="footer-item-list__item">
                                    <a className="footer-contacts__link" href="mailto:info@kkpartner.dk">Mail: info@kkpartner.dk</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-end grid-x grid-margin-x align-justify">
                        <div className="cell medium-3">
                            © 2021 KK Partner A/S
                        </div>
                        <div className="cell medium-5">
                            Designet af <a className="footer-end__link" target="_blank" href="https://www.kirk-holm.dk">KIRK &amp; HOLM</a> og <a className="footer-end__link" target="_blank" href="https://www.nobrainer.dk">Nobrainer Web</a>
                        </div>
                        <div className="cell medium-4 large-3 large-offset-1">
                            <div className="footer-end__tagline">Teknologisk infrastruktur</div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

    );
}

export default Footer;