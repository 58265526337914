import React, { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import AppContext from "../../appcontext"

const AddFirm = ({firms, setFirms, Logout}) => {
    const navigate = useNavigate()
    const app = useContext(AppContext)

    const [firmName, setFirmName] = useState()
    const [recipient, setRecipient] = useState()

    const CreateFirm = (event) => {
        event.preventDefault()
        var tempfirms = [...firms]
        // Temporary Object with Hook Values
        let obj = {
            name: firmName,
            recipient: recipient
        };

        // Create URL with Params
        let url = new URL(app.baseURL + "/addfirm");

        // Send request to Add a Firm
        fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(obj)
        })
        .then(() => {
            // Push to refresh page

        // Create URL with Params
        url = new URL(app.baseURL + "/firms");

        // Send Request to get all firms
        fetch(url, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => {
            if (!res.ok) {
                // If the response is unauthorized
                if (res.status === 401) {
                    // Delete cookie by setting expiration to a previous date
                    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax"
                    document.cookie = "LOGGEDIN= false"
                    Logout()
                    app.LogoutRequest()
                }

                navigate("/")
            } else {
                res.json()
                    .then(json => {
                        setFirms(json)

                    })
            }
        })
        })
    }

    return(
       <div style={{display: "flex", flexDirection: "column", border: "1px solid black", padding: "10px"}}>
           <h2>Tilføj Firma</h2>
           <form style={{display: "flex", flexDirection: "column"}} onSubmit={(event) => {
               event.preventDefault()
               CreateFirm(event)
               event.target.reset()}}>
               <label>Navn</label>
               <input required onChange={(e) => setFirmName(e.target.value)}/>
               <label>Intern modtager</label>
               <input required placeholder="eksempel@kk-partner.dk" onChange={(e) => setRecipient(e.target.value)} />
               <button type="submit">Tilføj</button>
           </form>
       </div>
    )
}
export default AddFirm